import { Box } from '@mantine/core';
import { useContext } from 'react';

import { LayoutContext, LayoutType } from '../shared/contexts/LayoutContext';

type ConstrainedLayoutProps = {
  children: React.ReactNode;
};

const ConstrainedLayout = ({ children }: ConstrainedLayoutProps) => {
  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;

  return (
    <Box maw={!isMobile ? 454 : undefined} m="auto">
      {children}
    </Box>
  );
};

export default ConstrainedLayout;
