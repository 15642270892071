import { notifications } from '@mantine/notifications';
import { TbCheck, TbSend, TbX } from 'react-icons/tb';

export const showErrorNotification = (errorMsg: string) => {
  notifications.show({
    color: 'red',
    icon: <TbX />,
    message: errorMsg,
    title: 'Error',
    withCloseButton: true,
  });
};

export const showSuccessNotification = (successMsg: string, title?: string) => {
  notifications.show({
    color: 'green',
    icon: <TbCheck />,
    message: successMsg,
    title: title ? title : 'Success',
    withCloseButton: true,
  });
};

export const showNotification = ({
  title,
  message,
}: {
  title: string;
  message?: string;
}) => {
  notifications.show({
    color: 'green',
    icon: <TbSend />,
    message,
    title,
    withCloseButton: true,
  });
};
