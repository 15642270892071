import { Box, Col, createStyles, Grid, Image, ScrollArea } from '@mantine/core';
import { useContext } from 'react';

import {
  LayoutContext,
  LayoutType,
} from '@/components/shared/contexts/LayoutContext';

import CottageLogo from '../shared/svg/CottageLogo';

const useStyles = createStyles((theme) => ({
  logoOnImage: {
    position: 'absolute',
    top: '16px',
    left: '16px',
    zIndex: 2,
  },
  col: {
    padding: '0px 0px 0px 0px',
  },
  rightCol: {
    padding: theme.spacing.xxl,
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      padding: theme.spacing.xl,
    },
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      padding: theme.spacing.xl,
      paddingBottom: '40px',
    },
  },
  gridFullHeight: {
    margin: 0, // removes Grid's default margin
  },
}));

type TwoColImageLayoutProps = {
  children: React.ReactNode;
  imageAltText: string;
  leftImagePath: string;
  bgColor?: string;
};

const TwoColImageLayout = ({
  bgColor,
  children,
  leftImagePath,
  imageAltText,
}: TwoColImageLayoutProps) => {
  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;
  const { classes, theme } = useStyles();

  return (
    <Grid className={classes.gridFullHeight} grow align="center">
      <Col
        order={isMobile ? 2 : 1}
        sm={6}
        className={classes.col}
        data-testid="grid-col"
      >
        {!isMobile && (
          <Box w={100} className={classes.logoOnImage}>
            <CottageLogo color="white" />
          </Box>
        )}
        <Image src={leftImagePath} alt={imageAltText} height="100vh" />
      </Col>
      <Col
        order={isMobile ? 1 : 2}
        sm={6}
        className={classes.col}
        data-testid="grid-col"
      >
        {isMobile && (
          <Box className={classes.rightCol} bg={bgColor}>
            <Box w={100} mb={48}>
              <CottageLogo color={theme.colors.blue[9]} />
            </Box>
            {children}
          </Box>
        )}
        {!isMobile && (
          <ScrollArea.Autosize className={classes.rightCol} mah="100vh">
            {children}
          </ScrollArea.Autosize>
        )}
      </Col>
    </Grid>
  );
};

export default TwoColImageLayout;
