import { Space, Text } from '@mantine/core';
import { TbExclamationCircle } from 'react-icons/tb';

import { useCottageFlags } from '@/lib/hooks/useCottageFlags';

import { CottageAlert } from './default';

export const ScheduledMaintenanceBanner = () => {
  const flags = useCottageFlags();
  const downtimeMessage = flags?.downtimeMessage;

  if (!downtimeMessage) {
    return <></>;
  }

  return (
    <>
      <CottageAlert
        color="orange"
        icon={<TbExclamationCircle />}
        title={'Maintenance Notification'}
      >
        <Text>{downtimeMessage}</Text>
      </CottageAlert>
      <Space h="md" />
    </>
  );
};
